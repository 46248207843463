@import '../../variables.scss';


.content {
  display: flex;
  justify-content: center;
  min-height: 300px;
  min-width: 400px;
  padding-top: 20px;
}

.dashboard-item {
  margin: 10px;
  border-color: teal;
  border-width: 2px;
  border-style: solid;
  height: 470px;
  overflow: hidden;
}

.pie-chart-caption {
  text-align: center;
  color: black;
}


