@import '../variables';
.landing-page {
  background-image: url('/images/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Nunito', sans-serif;
  .hero {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing-logo {
      color: white;
      align-self: start;
      display: flex;
      flex-direction: row;
      width: 100%;
      .brand {
        padding: 20px;
      }

      .landing-menu {
        align-self: end;
        color: white;
        float: right;
        align-items: flex-end;
      }
    }

    .landing-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .landing-menu {
      display: flex;
      flex-direction: row;
      padding: 20px;
    }

    .top-link {
      color: #fff;
      text-decoration: none;
    }

    .top-link-container {
      padding: 5px;
      padding-right: 10px;
    }

    .menu-spacer {
      display: flex;
      flex: 1;
    }

    .welcome-text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: whitesmoke;
      font-size: 2em;
      justify-content: center;
      align-items: center;
      padding: 15px;
      text-align: center;

      .discord {
        padding: 3px;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .link {
          padding: 10px;
          margin: 10px;
          background-color: #fff;
          border-width: 5px;
          border-color: #fff;
          border-style: solid;
          border-radius: 40px;
          width: 150px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        a {
          text-decoration: none;
        }

        img {
          display: inline-block;
          height: 35px;
          width: 35px;
          padding-right: 10px;
          text-align: center;
        }

        span {
          font-size: medium;
        }
      }

      .links {
        padding: 3px;
        color: white;
        display: flex;
        flex-direction: row;

        a {
          text-decoration: none;
        }

        .link {
          padding: 10px;
          margin: 10px;
          background-color: #fff;
          border-width: 5px;
          border-color: #fff;
          border-style: solid;
          border-radius: 40px;
          width: 150px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        img {
          display: inline-block;
          height: 35px;
          width: 35px;
          padding-right: 10px;
          text-align: center;
        }
        span {
          text-decoration: none;
          font-size: 1rem;
          color: #000;
          display: inline-block;
        }

        .link:hover {
          background-color: $secLight;
          border-color: $secLight;
        }
      }
    }
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .landing-page .hero .welcome-text .links {
    flex-direction: column;
  }

  .landing-page .hero .welcome-text .join-text {
    display: none;
  }
}
