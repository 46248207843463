@import '../variables';

body {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background: $primaryCol;
}

main {
  margin-left: 4rem;
}

.navbar {
  position: fixed;
  left: 0;
  background-color: $mainDark;
  transition: width 200ms;
  box-shadow: #00000080 4px 0px 10px -4px;
  z-index: 1001;
}

.navbar-nav {
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-star;
  align-items: center;
  font-size: 1rem;
}

.nav-item {
  width: 100%;
  cursor: pointer;
}

.navbar-spacer {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  height: 4rem;
  justify-content: center;
}

.nav-link-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  height: 4rem;
}

.logo-svg {
  justify-content: space-between;
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: $primaryCol;
}

.nav-item-selected {
  filter: grayscale(0%) opacity(0.7);
  background: $primaryCol;
  width: 100%;
  cursor: pointer;
}


/* Rotation Effect */

.logo {
  margin-bottom: 1rem;
  background: $mainDark;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.link-text {
  display: none;
  margin-left: 10px;
}


/* Mobile */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 4rem;
  }

  .navbar-nav {
    flex-direction: row;
    justify-content: space-around;
  }

  .nav-link {
    justify-content: center;
  }

  .logo {
    display: none;
  }

  main {
    margin-left: 0;
  }
}

/* Desktop */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 4rem;
    height: 100vh;
  }
}




