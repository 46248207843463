.about-page {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: calc(11px + 0.6vh);
  font-weight: 500;
  background-repeat: no-repeat;
  background-size: cover;
  .about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .text-wrapper {
      padding: 1.5em;
      width: 100%;
      box-sizing: border-box;
      .data-zone-link, .danger-zone-link {
        padding-left: .5em;
      }
    }
  }
}

@media (min-width: 576px) { 
  .about-page {
    .about-content {
      .text-wrapper {
        padding: 1em;
        width: 80%;
      }
    }
  }
}

@media (min-width: 1200px) { 
  .about-page {
    .about-content {
      .text-wrapper {
        padding: 1em;
        width: 70%;
      }
    }
  }
}
