@import "./../../variables.scss";

.natura-dropzone {
    &.mode-expanded {
        height: 100%;
        position: relative;

        &.is-dragged-over {
            .natura-dropzone-wrap {
                border-color: $primaryCol;
            }

            .natura-dropzone-bg-icon {
                font-size: 12em;
            }
        }

        > div {
            height: 100%;
        }

        .natura-dropzone-wrap {
            transition: all 0.2s linear;
            border: 5px dashed #d3d3d3;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &.mode-compact {
        .natura-dropzone-bg-icon,
        .natura-main-drag-message,
        .natura-secondary-drag-message {
            display: none;
        }
    }
}

.natura-dropzone-bg-icon {
    transition: all 0.2s linear;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    color: #ccc;
}

.natura-main-drag-message {
    font-size: 25px;
}

.natura-secondary-drag-message {
    margin-bottom: 8px;
}