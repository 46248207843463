.IbaListHeader {
  padding: 20px;
}

.threat-list {
  height: calc(100vh - 220px);
}

.hidden-progress {
  display: none;
}

.validation-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
