@import '../../variables.scss';

.popup-threat-mainText {
  font-size: x-large;
  padding-top: 5px;
  padding-bottom: 10px;
}

.popup-threat {
  min-width: 300px;
}

.leaflet-popup-content{
  min-width: 300px
}

.popup-threat-heading {
  font-size: medium;
  padding: 5px;
}

.popup-threat-login {
  text-align: center;
}



.map-with-sidebar {
  width: 60vw;
  margin-left: 40vw;
}

.leaflet-control-layers.leaflet-control {
  overflow: visible;
  margin-top: 10px;
}

.leaflet-container .leaflet-control-attribution {
  background: $mainMedium;
  a {
    color: $primaryCol;
  }
}
.leaflet-control-layers {
  max-height: 600px;
  overflow: auto;
}
.leaflet-control-layers,
.leaflet-bar {
  border: none !important;
  background: $mainMedium;
  color: #fff;
  border-radius: 0;
  margin-top: 10px !important;
}

.leaflet-control-layers-toggle {
  background-image: url(/images/layers.png);
  border: none;
  width: 38px !important;
  height: 38px !important;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/images/layers2x.png);
}

.leaflet-bar a {
  background: $mainMedium;
  color: #ddd;
  &:hover {
    background: $mainDark;
    color: #fff;
  }
}

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
  margin-left: 10px;
}

.leaflet-popup-content-wrapper {
  background-color: $primaryCol;
  color: #fff;
  border-radius: 1px;
}

.leaflet-popup-tip {
  background-color: $primaryCol;
}

.threat {
  color: $textColor;
}

.leaflet-container a.leaflet-popup-close-button {
  padding: 8px 8px 0 0;
  color: #fff;
}

.leaflet-popup-content {
  line-height: 1;
}

.leaflet-draw-draw-circlemarker {
  background-image: url(/images/add.png) !important;
  width: 50px !important;
  height: 50px !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  background-size: 40px 40px !important;
}

.leaflet-draw-actions {
  right: 38px !important;
}

.leaflet-control-geocoder-address-context {
  color: $secondary !important;
}

.leaflet-control-geocoder .leaflet-control-geocoder-alternatives a {
  color: #000 !important;
}

.leaflet-control-geocoder {
  background: #fff !important;
}

.legend {
  line-height: 18px;
  color: #555;
}
.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
}

.legend-info {
  padding: 6px 8px;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.legend-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.leaflet-control-geocoder-form {
  color: black;
}
