.feedback-btn {
  position: fixed;
  top: calc(100vh - 50vh);
  transform: rotateZ(90deg);
  right: -35px;
  z-index: 10000;
  span{
    color: #fff;
  }
  // position: absolute;
  // top: calc(100% - -10%);
  // right: 0;
}

.title {
  h6{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.topic-dropdown, .message-box {
  width: 100%;
}

