@import '../variables';
.bg {
  background-image: url('/imgages/background4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;

  .help-page {
    .help-content {
      color: white;

      .help-title {
        background-color: #e1b633;
        max-width: 75px;
        margin-top: 100px;
        padding: 1px 30px 1px 30px;
        line-height: 1em;
      }

      .help-text {
        margin: 30px 30px 40px 30px;
        width: 80vw;
        font-size: calc(11px + 0.6vh);
        font-weight: 500;
      }

      .btn {
        margin: 20px 10px 50px 0;
      }
    }

    .help-footer {
      position: fixed;
      bottom: 0;
      overflow: hidden;
      font-size: calc(10px + 0.3vw);
      width: 100vw;
      height: 60px;
      background-color: $mainLight;
      text-align: left;
      display: flex;
      align-items: center;
      .text-wrapper {
        margin: 5px 2vw 5px 5vw;
        p {
          color: #fdfdfc;
          margin: 0;
        }
      }
    }
  }
}
